






































































































































































































































import {
  computed,
  createComponent,
  ref,
  watch,
  SetupContext,
  onMounted
} from '@vue/composition-api'
import { firebaseApp, getWebPushToken } from '@/plugins/firestore'
import { navItems } from '@/pages/config'
import { postApiSrvWebPushToken, isNeedWebPush } from '@/models/api/user'

export default createComponent({
  components: {},
  setup(_props, context: SetupContext) {
    const waitLoadingPage = ref<boolean>(false)
    context.root.$nuxt.$on('waitLoadingPage', (isLoading: boolean = false) => {
      waitLoadingPage.value = isLoading
    })
    const currentItemIdx = ref<number>(0)
    const currentItem = computed(() => {
      return navItems[currentItemIdx.value]
    })
    watch(currentItemIdx, () => {
      if (currentItemIdx.value > 0) {
        context.root.$router.push(currentItem.value.items[0].link)
      }
    })

    const fetchLastReadMessage = async () => {
      // unread_messagesのデータを取得
      await context.root.$axios
        .$get(`/users/me/last_read_message`)
        .then((res) => {
          if (res.last_message_read_datetime) {
            // 古いSafariだとタイムゾーンがないとUTC扱いになるので注意
            const lastReadMessageDate = new Date(res.last_message_read_datetime)
            context.root.$store.commit(
              'message/setLastReadMessageDate',
              lastReadMessageDate
            )
          } else {
            console.log('unread messages is null')
          }
        })
        .catch((err: any) => console.log('unread messages get error', err))
    }

    // firebaseの認証されていない場合は、動かさないようにする
    const auth = firebaseApp.auth()
    auth.onAuthStateChanged((user) => {
      if (user) {
        fetchLastReadMessage()
        // firebaseのメッセージをバインディングさせる
        context.root.$store.dispatch('message/bindMessages').catch((error) => {
          console.error('Error binding messages: ', error)
        })
      }
    })

    const unreadBadge = computed(() => {
      const count = context.root.$store.getters['message/recentMessageCount']
      if (count > 9) {
        return '9+'
      } else if (count > 0) {
        return String(count)
      } else {
        return ''
      }
    })

    // WebPushトークンの取得メソッド
    const getReloadWebPushToken = async () => {
      if (context.root.$auth && context.root.$auth.user) {
        const user = context.root.$auth.user
        if (isNeedWebPush(user)) {
          if ('Notification' in window) {
            if (Notification.permission === 'granted') {
              try {
                console.log('[DEFAULT] Requesting token for web push.')
                const currentToken = await getWebPushToken()
                await postApiSrvWebPushToken(context.root.$axios, currentToken)
              } catch (err) {
                console.log('Error occurred.', err)
              }
            }
          }
        }
      }
    }
    // 画面表示時に notification が利用できるか
    const canNotification =
      'Notification' in window ? Notification.permission === 'granted' : false
    // ログイン時の通知許可等の処理が終わったか
    const notificationChecked = computed(
      () => context.root.$store.state.notificationChecked
    )

    // 画面表示時に呼出し
    onMounted(async () => {
      await getReloadWebPushToken()
    })

    // ログイン時の通知処理が終わった場合に呼出し
    watch(notificationChecked, async (doReload) => {
      if (doReload && !canNotification) {
        console.log('Permission changed.')
        await getReloadWebPushToken()
      }
    })

    // クーポン対応追加
    const currentItemsWebApp = computed(() => {
      const couponUser = context.root.$auth.user
        ? context.root.$auth.user.coupon_user
        : false
      return currentItem.value.items.filter((val) => {
        // クーポンユーザ出ない場合、クーポンメニューを非表示
        if (val.title === '利用規約') {
          val.title = '規約等'
        }
        if (val.title === 'アカウント確認/変更') {
          val.title = 'アカウント設定'
        }
        return (
          !(val.title === 'クーポン' && !couponUser) &&
          !(val.title === 'プライバシーポリシー')
        )
      })
    })

    // クーポン対応追加
    const currentItems = computed(() => {
      const couponUser = context.root.$auth.user
        ? context.root.$auth.user.coupon_user
        : false
      return currentItem.value.items.filter((val) => {
        // クーポンユーザ出ない場合、クーポンメニューを非表示
        return !(val.title === 'クーポン' && !couponUser)
      })
    })

    const logout = async () => {
      await context.root.$auth.logout().catch(console.error)
      await firebaseApp
        .auth()
        .signOut()
        .catch(console.error)
      context.root.$router.push('/login/')
    }

    if (context.root.$route) {
      const path = context.root.$route.path
      if (path.startsWith('/reservations/')) {
        currentItemIdx.value = 1
      } else if (path.startsWith('/account/')) {
        currentItemIdx.value = 2
      } else if (path.startsWith('/service/')) {
        currentItemIdx.value = 3
      }
    }

    const userName = computed(() => {
      return context.root.$auth.user
        ? `${context.root.$auth.user.last_name}${context.root.$auth.user.first_name}`
        : ''
    })

    const userId = computed(() => {
      return context.root.$auth.user ? context.root.$auth.user.id : ''
    })

    const checkSubNav = () => {
      if (currentItem.value === undefined) {
        return 'no-subnav'
      } else {
        return currentItem.value.items.length === 0 ? 'no-subnav' : false
      }
    }

    const checkNavForUndefined = () => {
      return (
        currentItem.value !== undefined && currentItem.value.items.length > 0
      )
    }

    const checkNavForUndefinedSpTab = () => {
      return currentItemIdx.value > 0 && currentItem.value.items.length > 0
    }

    return {
      waitLoadingPage,
      navItems,
      currentItemIdx,
      currentItem,
      userName,
      userId,
      logout,
      checkSubNav,
      checkNavForUndefined,
      currentItems,
      currentItemsWebApp,
      checkNavForUndefinedSpTab,
      unreadBadge
    }
  }
})
